.select {
    position: relative;

    .no-options {
        color: #000;
        background-color: #A48EE5;
        padding: 60px 0px;
        text-align: center;
        position: absolute;
        z-index: 2;
        width: 100%;
        // border: 1px solid var(--grey);
        // box-shadow: var(--shadow);
    }

    .expand-option,
    .options {
        // background-color: #A48EE5;
        // background-color: #fff;
        // border: 1px solid #A48EE5;
        border-left-width: 0.1em;
        border-style: solid;
        // border-color: var(--grey);
        border: 1px solid #ddd;
        list-style: none;
        margin-top: -3px;
        max-height: 300px;
        overflow-x: auto;
        padding-left: 0;
        animation-name: open;
        animation-duration: 0.7s;
        font-size: 13px;
        border-radius: 0px 0px 5px 5px;
        position: absolute;
        z-index: 4;
        background-color: #F3F7FD;
        width: 100%;
        border-radius: 7px;
        margin-top: 5px;

        // width: max-content;
        min-width: 100%;
        // box-shadow: var(--shadow);

        ::-webkit-scrollbar {
            width: 7px;
        }

        ::-webkit-scrollbar-thumb {
            // background-color: variables.$scrollColor;
            border-radius: 3px;
        }

        ::-webkit-scrollbar-track {
            background: #fff;
        }

        scrollbar-width: thin;

        li {
            list-style: none;
            color: #000;
            // height: 36px;
            word-wrap: break-word;

            ul {
                padding-left: 0;
            }
        }
    }

    @keyframes open {
        from {
            max-height: 0px;
        }

        to {
            max-height: 300px;
        }
    }

    .primary li,
    .options li {
        padding: 0.2rem;
        width: 100%;


    }

    .primary li:hover,
    .option-active,
    .options li:hover {
        background-color: #F3F7FD;
        color: #545454!important;
        cursor: pointer;

        .option.options-wrap {
            color: #545454 !important;
        }
    }

    .options li:not(:last-of-type) {
        border-bottom: 1px solid #ddd;
    }

    .open-top { 
        bottom: 19px;
    }

    .select-div {
        padding: 15px;
        white-space: nowrap;
        overflow: hidden;
        position: relative;
        // border-top-right-radius: 0 !important;
        // border-bottom-right-radius: 0 !important;
    }

    .vertical-line {
        display: inline-block;
        // border-left: 1px solid var(--autumn);
        height: 25px;
    }

    .downarrow {
        margin-right: 5px;
    }

    .searchdownarrow {
        margin-right: 10px;
        margin-top: 0px;
    }

    .zindex {
        z-index: 100;
    }

    .option-disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    .option-active .option,
    .options li:hover .option {
        color: #545454;
    }

    .option {
        padding: 4px 9px;
        width: 100%;
        color: #000;
    }

    .row-icon-view {
        flex-direction: row;
        display: flex;
    }

    .row-icons {
        height: 25px;
        width: 25px;
    }

    .expandIcon {
        margin: 0 3px;
    }

    .right-icon {
        position: absolute;
        float: right;
        right: 0px;
        top: -7px;
        margin-left: 37px;
        padding-left: 8px;
    }

    .custom-select {
        position: relative;
        cursor: pointer;
    }

    .down-arrow {
        position: absolute;
        right: 10px;
        top: 11px;
    }
}

.large-dd {
    .select .option {
        width: 98%;
    }

    .select {
        .primary li {
            // border-bottom: 1px solid var(--grey91);
        }

        .options-wrap {
            padding: 5px 0px;
        }
    }

    .expand-option {
        width: max-content;
    }

    .expand-option>li {
        padding: 6px 5px;
    }

    .select .code-div {
        min-width: 50px;
    }
}

.disabled-select {
    opacity: 0.5;
    width: 100%;
    position: relative;

    .down-arrow {
        position: absolute; 
        right: 10px;
        top: 11px;
    }
}

.abs-position{
    position: absolute;
    right: -50px;
    bottom: -44px;
}

.pagination {
    margin: 0;
}