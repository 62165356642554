@use "/src/variables";

.select {
  position: relative;

  .no-options {
    color: #000;
    background-color: #f6f8ff;
    padding: 60px 0px;
    text-align: center;
    position: absolute;
    z-index: 2;
    width: 100%;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    ;
  }

  .expand-option,
  .options {
    background-color: #f6f8ff;
    // border: 1px solid #f6f6f6;
    border-left-width: 0.1em;
    border-style: solid;
    border-color: #d9d9d9;
    border-top-width: 0;
    border-right-width: 0.1em;
    border-bottom-width: 0.1em;
    list-style: none;
    margin-top: -3px;
    max-height: 300px;
    overflow-x: auto;
    padding-left: 0;
    animation-name: open;
    animation-duration: 0.7s;
    font-size: 13px;
    border-radius: 0px 0px 5px 5px;
    position: absolute;
    z-index: 4;

    width: max-content;
    min-width: 100%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    ;

    ::-webkit-scrollbar {
      width: 7px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #bbbbbb;
      border-radius: 3px;
    }

    ::-webkit-scrollbar-track {
      background: #fff;
    }

    scrollbar-width: thin;

    li {
      list-style: none;
      color: #000;

      ul {
        padding-left: 0;
      }
    }
  }

  @keyframes open {
    from {
      max-height: 0px;
    }

    to {
      max-height: 300px;
    }
  }

  .primary li,
  .options li {
    padding: 0.2rem;
    width: 100%;
  }

  .primary li:hover,
  .option-active,
  .options li:hover {
    background-color: #435baa;
    color: #fff;
    cursor: pointer;

    .option.options-wrap {
      color: #fff;
    }
  }

  .options li:not(:last-of-type) {
    border-bottom: 1px solid #f6f6f6;
  }

  .open-top {
    bottom: 19px;
  }

  .select-div {
    padding: 9px;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
  }

  .vertical-line {
    display: inline-block;
    border-left: 1px solid #bbbbbb;
    height: 25px;
  }

  .downarrow {
    margin-right: 12px;
  }

  .searchdownarrow {
    margin-right: 12px;
  }

  .zindex {
    z-index: 100;
  }

  .option-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .option-active .option,
  .options li:hover .option {
    color: #fff;
  }

  .option {
    padding-left: 5px;
    width: 100%;
    color: #000;
  }

  .row-icon-view {
    flex-direction: row;
    display: flex;
  }

  .row-icons {
    height: 25px;
    width: 25px;
  }

  .expandIcon {
    margin: 0 3px;
  }

  .right-icon {
    // position: absolute;
    float: right;
    right: 0px;
    top: -7px;
    margin-left: 37px;
    padding-left: 8px;
  }
}

.large-dd {
  .select .option {
    width: 98%;
  }

  .select {
    .primary li {
      border-bottom: 1px solid #e8e8e8;
    }

    .options-wrap {
      padding: 5px 0px;
    }
  }

  .expand-option {
    width: max-content;
  }

  .expand-option>li {
    padding: 6px 5px;
  }

  .select .code-div {
    min-width: 50px;
  }
}

.disabled-select {
  opacity: 0.5;
  width: 100%;

  .right-icon {
    // position: absolute;
    float: right;
    right: 0px;
    top: -7px;
    margin-left: 37px;
    padding-left: 8px;
  }
}