/********** Text Colors **********/

$color-primary: #0F5EDD;
$color-primary-light: #f0feff;
$color-secondary: #0F5EDD;
$color-secondary-light: #e9f1ff;
$color-secondary-white: #FFFFFF;
$color-blue-dark: #0B1956;

/********** Border Colors **********/

$form-control-brorder :rgba(15, 94, 221, 0.30);
$border-light: #E3E6EE;

/********** Background Color **********/

$white: #FFFFFF;
$blue: #0F5EDD;

/********** Font Weight **********/

$font-light: 300;
$font-regular: 400;
$font-lighter: 500;
$font-medium: 600;
$font-bold: 700;