@import "../../assets/css/variables.scss";

#main-wraper {
  background: #fff !important;
}

.mobile-language-transalte {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}


.login-page {
  background-color: #f7f7f7;
  width: 100%;
  height: 100vh;
  display: inline-block;
  display: flex;
  align-items: center;

  ::-ms-input-placeholder {
    color: #0B1956;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  ::placeholder {
    color: #0B1956;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  h1 {
    font-family: "Be Vietnam Pro", sans-serif;
    color: $color-blue-dark;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: $font-bold;
    line-height: 28px;
    margin-bottom: 0;
  }

  label {
    color: #0B1956;
    font-size: 12px;
    font-style: normal;
    font-weight: $font-regular;
    line-height: 12px;
    padding-bottom: 7px;
  }

  .form-control {
    height: 50px;
    color: $color-blue-dark;
    font-size: 14px;
    font-weight: $font-regular;
    line-height: 18px;
    border-radius: 12px;
    border: 1px solid rgba(15, 94, 221, 0.20);
    background: #F3F7FD;
  }

  .blue-card-box {
    background: #0F5EDD;
    // height: 90vh;
  }

  .login-boxes {
    background: #0F5EDD;
    border-radius: 24px;
    padding: 0;
  }

  span.left-corner {
    background: #F3F7FD;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-color: rgba(15, 94, 221, 0.20);
  }

  span.right-corner {
    background: #F3F7FD;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-color: rgba(15, 94, 221, 0.20);
  }

  .vector-shape-bg {
    background: url('../../assets/images/vector-shape.svg') center top no-repeat;
    background-size: contain;
    width: 100%;
    position: relative;
  }

  h6 {
    color: $color-blue-dark;
    font-size: 12px;
    font-style: normal;
    font-weight: $font-regular;
    line-height: 16px;
    display: flex;
    flex-direction: row;
  }

  h6:before,
  h6:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #C9C9C9;
    margin: auto;
  }

  .forgot {
    color: $color-blue-dark;
    font-style: normal;
    font-weight: $font-regular;
    line-height: 16px;
    text-decoration-line: underline;
  }

  .select-box {
    border-radius: 8px;
    border: 1px solid rgba(15, 94, 221, 0.30);
    color: $color-blue-dark;
    font-size: 12px;
    font-style: normal;
    font-weight: $font-regular;
    line-height: 12px;
    height: 34px;
    width: 150px;
    position: absolute;
    right: 40px;
    top: 50px;
    background-color: #F3F7FD;
  }

  .rounded-left {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .rounded-right {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .p100 {
    padding: 70px;
  }

  .account {
    color: $color-blue-dark;
    font-weight: $font-regular;
  }

  .arrow-pos {
    position: absolute;
    right: 23px;
    top: 10px;
    cursor: pointer;
  }

  .arrow-pos-g {
    height: 25px;
    position: absolute;
    right: 10px;
    top: 12px;
    z-index: 99;
  }

  /********** Login Nav Tabs **********/

  .login-tabs .nav.nav-tabs {
    border-bottom: 0;
    background: #f4f7fd;
    // width: 202px;
    border-radius: 50px;
    border: 1px solid #C5D9F6;
    position: absolute;
    right: 0;
    bottom: -72px;
    z-index: 9;
  }

  .login-tabs .nav-item {
    width: 90px;
  }

  .login-tabs .nav-item button {
    color: #0B1956;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    background: #f4f7fd;
    border-radius: 50px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  .login-tabs .nav-tabs .nav-link.active,
  .login-page .nav-tabs .nav-item.show .nav-link {
    color: #FFF;
    border-bottom: 0 !important;
    background: #0B1956;
    border-radius: 50px;
  }


  /********** Signup Nav Tabs **********/

  .nav-item {
    width: 50%;
  }


  .nav-item button {
    border: 0 !important;
    color: #888;
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    font-weight: 500;
    color: #0F5EDD;
    border-bottom: 1px solid #0F5EDD !important;

  }

  /********** Login Carousel **********/

  .login-carousel {
    h5 {
      color: #FFF !important;
      text-align: center;
      font-size: 24px !important;
      font-weight: $font-medium;
      line-height: 32px;
    }

    p {
      color: #FFF;
      text-align: center;
      font-size: 16px;
      font-weight: $font-regular;
      line-height: 24px;
    }

    .carousel-control-next,
    .carousel-control-prev {
      display: none;
    }

    .carousel-inner {
      text-align: center;
    }

    .carousel-caption {
      bottom: 0;
      position: relative;
      padding-top: 0;
      left: auto;
      right: auto;
      padding-top: 30px;
      padding-bottom: 50px;
    }

    .carousel-indicators [data-bs-target] {
      width: 24px;
      height: 4px;
      border: 0;
      border-radius: 100px;
      background-color: #FFF;
    }

  }


  .time-pickers select.form-select {
    border-radius: 12px;
    border: 1px solid rgba(15, 94, 221, 0.2);
    background: #F3F7FD;
    color: #545454;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    height: 48px;
  }

  /********** Media Query ***********/

  @media(max-width:1024px) {
    .p100 {
      padding: 30px;
    }

    .select-box {
      right: 20px;
      top: 25px;
    }
  }

  @media(max-width:768px) {
    .p100 {
      padding: 30px;
    }

    .select-box {
      right: 20px;
      top: 25px;
    }

    .pt30 {
      padding-top: 40px;
    }

  }

  @media(max-width:767px) {
    padding: 10px 0;
    height: 100vh;
    // background: #0F5EDD;
    position: relative;
    background-color: #FFF;

    .align-items-center {
      align-items: inherit !important;
    }

    .login-carousel h5 {
      font-size: 25px !important;
    }

    .login-tabs .nav-item {
      width: 80px;
    }

    .login-tabs .nav-item button {
      font-size: 11px;
    }

    .p100 {
      padding: 20px;
    }

    .rounded-left {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 0;
      border-top-right-radius: 20px;
    }

    .rounded-right {
      border-top-right-radius: 0;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    .select-box {
      right: 20px;
      top: 15px;
    }

    .pt30 {
      padding-top: 40px;
    }

    .plr {
      padding-left: 0;
      padding-right: 0;
    }

  }


}

@media(max-width:767px) {
  .mobile-carousel {
    background-color: #0F5EDD;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 999;
    top: 0;


  }

  .login-carousel {
    h5 {
      color: #FFF !important;
      text-align: center;
      font-size: 24px !important;
      font-weight: $font-medium;
      line-height: 32px;
    }

    p {
      color: #FFF;
      text-align: center;
      font-size: 16px;
      font-weight: $font-regular;
      line-height: 24px;
    }

    .carousel-control-next,
    .carousel-control-prev {
      display: none;
    }

    .carousel-inner {
      text-align: center;
      margin-top: 100px;
    }

    .carousel-caption {
      bottom: 0;
      position: relative;
      padding-top: 0;
      left: auto;
      right: auto;
      padding-top: 30px;
      padding-bottom: 50px;
    }

    .carousel-indicators [data-bs-target] {
      width: 24px;
      height: 4px;
      border: 0;
      border-radius: 100px;
      background-color: #FFF !important;
    }

  }

  .vector-shape-bg {
    background: url('../../assets/images/vector-shape.svg') center top no-repeat;
    background-size: contain;
    width: 100%;
    position: relative;
  }
}


.mobile-profile-modal .nav-item {
  width: 33%;
}

.btn-file input[type=file] {
  padding-top: 15px;
  color: #0B1956 !important;
  font-size: 14px !important;
}

.btn-file label {
  padding-bottom: 0 !important;
}