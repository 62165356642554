@import "../../assets/css/variables.scss";

.mobile-sticky-bottom {
  background-color: #fff;
  border-top: 1px solid #B7CFF5;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 99;

  nav {
    flex-wrap: nowrap;
    overflow: auto;
    text-align: center;
  }

  a.nav-link {
    color: #7C8CA9;
    font-size: 11px;
    font-weight: 400;
    margin: 0 5px;
  }

  a.nav-link span {
    opacity: 0.7;
  }

  .btn {
    height: 43px;
    line-height: 26px;
    text-align: center;
    width: 100%;
    border-radius: 100px;
  }

  .btn-brand{
   background: $blue;
   color: $color-secondary-white;
  }



}

.sticky-top-mo {
  background-color: #f6f9ff;
  margin-top: -18px;
  padding-bottom: 5px;
  padding-top: 25px;
  position: -webkit-sticky;
  position: sticky;
  top: 47px;
  z-index: 2;
}

.simplebar-scrollbar::before {
  background: #0F5EDD !important;
  opacity: 1;
  transition: opacity 0.2s linear;
}

.simplebar-horizontal {
  height: 7px !important;
  background: #CFDFF8;
  border-radius: 10px;
  margin-top: -40px;
}

.carousel-indicators {
  margin-bottom: -11px;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {}