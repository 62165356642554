@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "../css/variables.scss";

body {
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: $color-blue-dark;
}

.modal-open {
  padding: 0 !important;
}

.custom-modal-box.modal.show {
  padding-left: 0 !important;
}

.heading-font {
  font-family: "Be Vietnam Pro", sans-serif;
}

.form-control:focus,
.btn-close:focus {
  outline: 0;
  box-shadow: none;
}

.font-10 {
  font-size: 10px;
}

.font-11 {
  font-size: 11px;
}

.c {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
  line-height: 20px;
}

.font-15 {
  font-size: 15px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px;
}

.font-28 {
  font-size: 28px;
}

.font-36 {
  font-size: 36px;
}

.line-height40 {
  line-height: 40px;
}

.line-height24 {
  line-height: 24px;
}

.heading-1 {
  font-size: 29px;
  font-weight: 500;
}

.font-bold {
  font-weight: $font-bold;
}

.font-medium {
  font-weight: $font-medium;
}

.font-light {
  font-weight: $font-light;
}

.font-regular {
  font-weight: $font-regular;
}

.font-lighter {
  font-weight: $font-lighter;
}

a {
  text-decoration: none;
  transition: 0.4s;

  &:hover {
    color: $color-secondary !important;
  }
}

.text-brand {
  color: $color-primary;
}

.text-brand-dark {
  color: $color-secondary;
}

.text-green {
  color: #1ea93d;
}

.text-dark-blue {
  color: #0b1956;
}

.text-light-blue {
  color: #0f5edd !important;
}

.text-lightw-blue {
  color: #0b1956;
}

.text-gery {
  color: #545454 !important;
}

.text-gery-dark {
  color: #888888;
}

.text-light-green {
  color: #25ed8d;
}

.form-select:focus {
  box-shadow: none !important;
}

.text-orange {
  color: #e98000;
}

.text-red {
  color: #f44336;
}

.btn-brand-1 {
  background-color: $color-primary;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  height: 50px;
  border: 0;
  padding: 5px 25px;
  line-height: 39px;

  &:hover {
    background-color: $color-secondary;
    color: #fff !important;
  }

  &.btn-sm {
    height: 34px;
    padding: 4px 19px;
    font-weight: 500;
  }
}

.btn-outline-brand,
.btn-brand {
  @extend .btn-brand-1;
  background-color: transparent;
  border: 1px solid $color-primary;
  color: $color-primary;

  &:hover {
    background-color: $color-primary;
    border-color: $color-primary;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.form-check-input:checked {
  background-color: $color-primary;
  border-color: $color-primary;
}

.page-title {
  color: $color-primary;
  font-size: 20px;
  font-weight: $font-medium;
  line-height: 24px;
  margin-bottom: 0;
}

/********** Left Side Menu **********/

.layout-veritcle-menu {
  display: flex;
  min-height: 100vh;

  .verticle-menu {
    width: 280px;
    height: calc(100vh - 0px);
    position: sticky;
    top: 0;
    z-index: 9;
    background: #0f5edd url("../../assets/images/left-panel-shape.png") center 30% no-repeat;
    background-size: contain;

    .menu-list {
      .menu-item {
        font-family: "Be Vietnam Pro", sans-serif;
        transition: 0.3s;
        display: flex;
        gap: 10px;
        align-items: center;
        padding: 7px 16px;
        height: 44px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 8px;
        color: #fff;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;

        &:hover,
        &:focus {
          opacity: 1;
          background-color: $color-primary;
          color: #fff !important;
        }

        .menu-icon {
          width: 22px;
          height: 22px;
        }
      }

      a.active,
      a.menu-item:hover {
        background: #fff;
        color: #0f5edd !important;
        border-radius: 12px;
      }

      .dashboard-icon {
        background: url(../../assets/images/left-icon/dashboard.svg) left top no-repeat;
        width: 24px;
        height: 24px;
      }

      a.menu-item:hover span.dashboard-icon {
        background: url(../../assets/images/left-icon/dashboard-act.svg) 5px 3px no-repeat;
        width: 24px;
        height: 24px;
      }

      .doctors-icon {
        background: url(../../assets/images/left-icon/stethoscope.svg) left top no-repeat;
        width: 24px;
        height: 24px;
      }

      a.menu-item:hover span.doctors-icon {
        background: url(../../assets/images/left-icon/stethoscope-act.svg) 0 0px no-repeat;
        width: 24px;
        height: 24px;
      }

      .patients-icon {
        background: url(../../assets/images/left-icon/patient.svg) left top no-repeat;
        width: 24px;
        height: 24px;
      }

      a.menu-item:hover span.patients-icon {
        background: url(../../assets/images/left-icon/patient-act.svg) 0 0 no-repeat;
        width: 24px;
        height: 24px;
      }

      .appointments-icon {
        background: url(../../assets/images/left-icon/appointments.svg) left top no-repeat;
        width: 24px;
        height: 24px;
      }

      a.menu-item:hover span.appointments-icon {
        background: url(../../assets/images/left-icon/appointments-act.svg) 0 0 no-repeat;
        width: 24px;
        height: 24px;
      }

      .learning-icon {
        background: url(../../assets/images/left-icon/learning.svg) left top no-repeat;
        width: 24px;
        height: 24px;
      }

      a.menu-item:hover span.learning-icon {
        background: url(../../assets/images/left-icon/learning-act.svg) 0 0 no-repeat;
        width: 24px;
        height: 24px;
      }

      .schedule-icon {
        background: url(../../assets/images/left-icon/schedule.svg) left top no-repeat;
        width: 24px;
        height: 24px;
      }

      a.menu-item:hover span.schedule-icon {
        background: url(../../assets/images/left-icon/schedule-act.svg) 0 0 no-repeat;
        width: 24px;
        height: 24px;
      }

      .messages-icon {
        background: url(../../assets/images/left-icon/sms.svg) left top no-repeat;
        width: 24px;
        height: 24px;
      }

      a.menu-item:hover span.messages-icon {
        background: url(../../assets/images/left-icon/sms-act.svg) 0 0 no-repeat;
        width: 24px;
        height: 24px;
      }

      .need-icon {
        background: url(../../assets/images/left-icon/helpcenter.svg) left top no-repeat;
        width: 24px;
        height: 24px;
      }

      a.menu-item:hover span.need-icon {
        background: url(../../assets/images/left-icon/helpcenter-act.svg) 0 0 no-repeat;
        width: 24px;
        height: 24px;
      }

      .settings-icon {
        background: url(../../assets/images/left-icon/settings.svg) left top no-repeat;
        width: 24px;
        height: 24px;
      }

      a.menu-item:hover span.settings-icon {
        background: url(../../assets/images/left-icon/settings-act.svg) 0 0 no-repeat;
        width: 24px;
        height: 24px;
      }

      .chapter-icon {
        background: url(../../assets/images/left-icon/chapter.svg) left top no-repeat;
        width: 24px;
        height: 24px;
      }

      a.menu-item:hover span.chapter-icon {
        background: url(../../assets/images/left-icon/chapter-act.svg) 0 0 no-repeat;
        width: 24px;
        height: 24px;
      }

      .quiz-icon {
        background: url(../../assets/images/left-icon/quiz.png) left top no-repeat;
        width: 24px;
        height: 24px;
      }

      a.menu-item:hover span.quiz-icon {
        background: url(../../assets/images/left-icon/quiz-act.png) 0 0 no-repeat;
        width: 24px;
        height: 24px;
      }

      .certificate-icon {
        background: url(../../assets/images/left-icon/certificate.png) left top no-repeat;
        width: 24px;
        height: 24px;
      }

      a.menu-item:hover span.certificate-icon {
        background: url(../../assets/images/left-icon/certificate-act.png) 0 0 no-repeat;
        width: 24px;
        height: 24px;
      }

      .dropdown-toggle {
        @extend .menu-item;
        border: 0;
        background-color: transparent;

        &::after {
          border: 0;
        }

        &.show {
          background-color: $color-primary-light;
          opacity: 1;
        }
      }

      .dropend {
        text-align: center;
      }

      .dropdown-menu {
        left: -3px !important;
        border-radius: 0 6px 6px 0;
        background-color: $color-primary;

        .dropdown-item {
          color: #fff;
          font-size: 14px;
          padding: 9px 13px;
          margin-bottom: 3px;

          &:hover {
            background-color: #ffffff2b;
            color: #ffffff !important;
          }
        }
      }
    }

    .tooltip-inner {
      background-color: #343434 !important;
    }
  }

  .app-body {
    width: calc(100% - 75px);
    padding-top: 100px;
    padding-bottom: 25px;
  }

  @media (max-width: 767px) {
    .app-body {
      width: calc(100% - 0px);
    }

    .verticle-menu {
      margin-left: -280px;
    }
  }
}

.ht {
  width: calc(100% - 75px);
}

/********** Header **********/

.site-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;

  .header-nav {
    .navbar {
      padding: 0;
    }
  }

  .header-nav {
    background-color: $color-secondary-white;
    padding: 0;
    height: 100px;
    border-bottom: 1px solid #b7cff5;

    .v-devider {
      display: inline-block;
      width: 1px;
      background: #525252;
      height: 20px;
      margin: 0 15px;
    }
  }

  .profile-dd {
    .dropdown-menu {
      min-width: 200px;
      top: 68px;
      border: 0;
      box-shadow: 0px 3px 10px #0000002b;

      .dropdown-item:hover a,
      .dropdown-item:hover .icon {
        color: #000 !important;
      }
    }

    .btn {
      background-color: transparent;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 0;

      &::after {
        border: 0;
      }

      border-radius: 30px;
      padding: 5px 5px;
    }

    .icon {
      width: 18px;
      height: 18px;
      margin-right: -4px;
      color: #4b4b4b;
    }
  }

  .help-btn {
    background-color: #f8f8ff;
    font-size: 14px;
    color: $color-primary;
    font-weight: $font-medium;
    height: 48px;
    padding: 8px 15px;
  }

  a {
    color: #0b1956;
    font-size: 14px;
  }
}

/********** Right side container **********/

.app-body {
  background: #f3f7fd;
}

/********** SearchBox **********/

.search-box {
  border: 1px solid $form-control-brorder;
  border-radius: 100px;
  background: #fff;
  margin-top: 8px !important;

  .form-control {
    height: 40px;
    border: 0;
    padding-left: 0;
    color: #545454;
    font-size: 14px;
    margin-left: 0 !important;
    background: transparent;

    &::placeholder {
      color: #545454;
      font-size: 13px;
    }
  }

  .input-group-text {
    background-color: #fff;
    border: 0;
    height: 40px;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }

  .icon {
    width: 18px;
    height: 18px;
    color: #545454;
  }
}

.notification-dd {

  //@extend .profile-dd;
  .dropdown-menu {
    min-width: 440px;
    margin-top: 10px;
    border: 1px solid rgba(15, 94, 221, 0.3);
    border-radius: 16px;
  }

  >.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    background: transparent !important;

    img {
      border-radius: 0;
    }

    &:hover {
      background: transparent !important;
    }

    &::after {
      content: none;
    }
  }

  .notification-item {
    display: flex;
    gap: 15px;
    font-size: 13px;
    padding: 10px 0px;

    .read-icon {
      position: relative;
      background-color: $color-secondary-light;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;

      .icon-unread {
        position: absolute;
        right: 0;
        top: 5px;
        color: #cd2424;
      }
    }

    &:hover {
      background-color: $color-primary-light;
      cursor: pointer;
    }
  }
}

/********** Start Ratings **********/

.ratings {
  svg {
    color: #1ea93d;
  }
}

/********** Custom Classes **********/

.logo-bar {
  padding-top: 40px;
  padding-bottom: 20px;
}

.gery-box-card {
  border-radius: 12px;
  border: 1px solid rgba(15, 94, 221, 0.2);
  background: #f3f7fd;
  padding: 15px;
}

.gery-box-card button,
.gery-box-card .btn-primary,
.gery-box-card .btn:first-child:active {
  background: transparent;
  border: 0;
  color: #0f5edd;
  font-size: 14px;
  font-weight: 400;
  padding: 0;
}

.gery-box-card .dropdown-toggle::after {
  display: none;
}

.gery-box-card a.dropdown-item {
  color: $color-blue-dark;
  font-size: 12px;
  font-style: normal;
  font-weight: $font-regular;
}

.gery-box-card .dropdown-menu.show.dropdown-menu-end {
  border-radius: 12px;
  border: 1px solid #0f5edd;
  background: #fff;
  margin-top: 5px;
}

a.upload-img,
button.upload-img {
  border-radius: 20px;
  background: $blue;
  display: inline-block;
  color: $color-secondary-white !important;
  font-size: 12px;
  font-style: normal;
  font-weight: $font-medium;
  width: 150px;
  height: 36px;
  padding: 6px 12px;
  line-height: 25px;
}

a.upload-img:hover,
button.upload-img:hover {
  color: $color-secondary-white !important;
}

a.remove-photo {
  color: $color-secondary;
  font-size: 14px;
  font-style: normal;
  font-weight: $font-lighter;
  text-decoration: underline;
  display: inline-block;
  padding-top: 10px;
}

.card-box {
  padding: 20px;
  border-radius: 16px;
  border: 1px solid rgba(15, 94, 221, 0.3);
  background: #fff;
  transition: 0.3s;

  .min-height {
    min-height: 185px;
    position: relative;

    @media (max-width: 767px) {
      min-height: auto;

      .font-14 {
        font-size: 10px;
        line-height: 18px;
        display: inherit;
      }

      .font-36 {
        font-size: 24px;
      }

      .font-regular {
        font-weight: 400;
      }

      .position-absolute {
        position: inherit !important;
      }

      .ratings svg {
        width: 13px;
      }

      .fl {
        float: left;
      }
    }
  }

  &:hover {
    box-shadow: 0 1px 15px 0 #0000002e;
  }

  @media (max-width: 767px) {
    padding: 12px;
  }
}

.card-box-blue {
  padding: 20px;
  border-radius: 16px;
  border: 1px solid #0b1956;
  background: #fff;
  transition: 0.3s;

  &:hover {
    box-shadow: 0 1px 15px 0 #0000002e;
  }
}

/********** Custom Modals **********/

.modal-content {
  padding: 30px;
}

.custom-modal-box .modal-content {
  padding: 44px;
}

.prescription-modal-box .modal-content {
  padding: 0;
}

.prescription-modal .modal-content {
  padding-bottom: 0;
}

.custom-modal-box .vector-shape-bg {
  background: url(../../assets/images/vector-shape.svg) bottom right no-repeat;
  background-size: auto;
  background-size: contain;
  transform: rotate(165deg);
  position: absolute;
  right: -24px;
  bottom: 0;
  width: 240px;
  height: 220px;
}

.custom-modals {
  input {
    border-radius: 16px;
    border: 1px solid rgba(15, 94, 221, 0.2);
    background: #f3f7fd;
    width: 80px;
    height: 80px;
    text-align: center;
    color: #0b1956;
    font-size: 36px;
    font-style: normal;
    font-weight: $font-regular;
    line-height: 40px;
  }
}

.custom-modal-box .modal-header,
.prescription-modal-box .modal-header {
  border-bottom: 0;
}

.custom-modal-box .profile-bg-border {
  border: 3px solid #b7cff5;
  border-radius: 100%;
}

.btn-close {
  background: url(../images/close-btns.svg) right top no-repeat;
  position: absolute;
  opacity: 1;
  width: 30px;
  height: 30px;
  // right: 25px;
  // top: 25px;
  right: 40px;
  top: 40px;
  z-index: 99;
}

.modal-90w {
  max-width: 50%;
}

.modal-65w {
  max-width: 65%;
}

.modal-shape-bg {
  background: url(../images/modal-shape.svg) right top no-repeat;
  height: 640px;
  width: 100%;
}

.simplebar-scrollbar::before {
  background: #0f5edd !important;
  opacity: 1;
  transition: opacity 0.2s linear;
}

.simplebar-vertical {
  width: 6px !important;
}

.simplebar-vertical {
  background: #cfdff8;
  border-radius: 10px;
}

/********** Mobile Notification List **********/
.inner-header {
  left: 0;

  .page-title {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
  }

  .header-nav-btn {
    height: 30px;
    border-radius: 6px;
    color: #0b1956 !important;
    padding: 5px 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #f3f7fd;
    }
  }

  .header-nav {
    padding: 17px 15px;
  }

  @media (max-width: 767px) {
    .header-nav {
      padding: 9px 15px;
    }

    .header-nav-btn {
      background-color: #f3f7fd;
    }

    .help-btn {
      background-color: transparent;
    }
  }
}

.mobile-notification-list {
  margin-top: 65px;

  .notification-item {
    display: flex;
    gap: 15px;
    font-size: 13px;
    padding: 10px 10px;
    border-bottom: 1px solid $border-light;

    .read-icon {
      position: relative;
      background-color: $color-secondary-light;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;

      .icon-unread {
        position: absolute;
        right: 0;
        top: 5px;
        color: #cd2424;
      }
    }

    &:hover {
      background-color: $color-primary-light;
      cursor: pointer;
    }
  }
}

.mobile-profile-dd {
  .dropdown-item {
    font-size: 13px;
    font-weight: $font-medium;
    padding: 9px 6px;

    .icon {
      color: #4a5264;
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }

    &:hover {
      background-color: $color-primary-light;
      color: #000;
    }
  }
}

.profile-dd.dropdown button {
  background: transparent;
  border: 0;
  padding: 0;
  display: inline-flex;
}

.profile-dd .dropdown-toggle::after {
  margin-top: 20px;
}

.profile-dd .dropdown-item {
  padding: 0 15px;
  font-size: 12px;
}

.radio-card-option {
  display: block;

  .btn {
    height: auto;
    text-align: left;
    border-radius: 10px !important;
    display: block;
    padding: 15px 20px;
  }

  .radio-icon {
    width: 18px;
    height: 18px;
    border: 1px solid $form-control-brorder;
    border-radius: 50px;
  }

  .btn-check:checked+.btn {
    .radio-icon {
      border-width: 6px;
      border-color: $color-primary;
    }

    border-color: $color-primary !important;
  }
}

.mobile-search {
  margin-left: 273px;
}

.iPad-search {
  margin-right: 10px;
}

/********** Filter section **********/

.filter-section {
  position: relative;

  button.btn-primary,
  button .btn:hover {
    background: none !important;
    padding: 0;
  }

  span.all-btn {
    background: #0f5edd;
    border-radius: 10px !important;
    padding: 8px 10px;
    height: 32px;
    width: auto;
    display: inline-block;
    line-height: 16px;
  }

  .horizontal-tabs {
    padding-left: 10px;

    a {
      display: inline;
      color: #545454;
      font-size: 12px;
      font-weight: $font-lighter;
      line-height: 16px;
      padding: 0 15px;
      vertical-align: bottom;
    }

    a:hover {
      color: #0b1956 !important;
    }

    a.active:hover {
      color: #fff !important;
    }

  }

  .dropdown-toggle::after {
    display: none;
  }

  .right-position {
    position: absolute;
    right: 15px;
  }

  .list li {
    display: table-cell;
    position: relative;
    text-align: center;
    cursor: grab;
    cursor: -webkit-grab;
    color: #efefef;
    vertical-align: middle;
  }

  .scroller {
    text-align: center;
    cursor: pointer;
    //display:none;
    white-space: no-wrap;
    vertical-align: middle;
    background-color: #fff;
  }

  .scroller-right {
    float: right;
  }

  .scroller-left {
    float: left;
  }

  .filter-dropdown {
    margin-right: 10px;

    .dropdown-menu {
      width: 300px;
      border: 0;
      box-shadow: 2px 3px 15px 0px rgba(0, 0, 0, 0.12);
      border-radius: 16px;
      padding: 15px;

      .filter-title {
        font-size: 15px;
        font-weight: $font-medium;
        padding: 4px 15px 0;
      }

      .btn {
        height: 40px;
      }
    }

    button.apply-btn {
      border-radius: 100px;
      background: #0f5edd !important;
      padding: 5px 25px;
      font-size: 15px;
      font-weight: $font-lighter;
      color: $color-secondary-white;
      margin-top: 10px;
      width: 50%;
    }

    button.reset-btn {
      border-radius: 100px;
      background: #fff !important;
      border: 1px solid #0f5edd;
      padding: 5px 25px;
      font-size: 15px;
      font-weight: $font-lighter;
      color: $color-secondary;
      margin-top: 10px;
      width: 50%;
    }
  }

  .action-dropdown {
    @extend .filter-dropdown;

    .dropdown-menu {
      width: auto;
      min-width: 92px;

      .dropdown-item {
        font-size: 13px;
        font-weight: $font-medium;
      }
    }
  }

  @media (max-width: 768px) {
    .horizontal-tabs {
      width: 240px;
    }

    .horizontal-tabs ul.nav {
      flex-wrap: nowrap;
      overflow: auto;
    }

    .horizontal-tabs a {
      padding: 0 10px;
    }
  }

  @media (max-width: 767px) {
    .none {
      display: none;
    }
  }

  .horizontal-tabs a.active {
    background: #0f5edd;
    color: #fff;
    border-radius: 10px !important;
    padding: 8px 10px;
    height: 32px;
    line-height: 16px;
    display: inline-block;
  }
}

/********** Messages Chat Box **********/

.messages-section {
  .box {
    position: relative;
    width: 100%;
    border-radius: 16px;
    border: 1px solid #0b1956;
    background: #fff;
  }

  .box-title {
    color: #fff;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 16px;
  }

  .box-header.with-border {
    border-radius: 16px 16px 0px 0px;
    background: #0b1956;
    height: 45px;
    padding: 15px;
  }

  .box-header {
    color: #444;
    display: block;
    padding: 10px;
    position: relative;
  }

  .box-header {
    color: #444;
    display: block;
    padding: 10px;
    position: relative;
  }

  .box-header>.box-tools {
    position: absolute;
    right: 15px;
    top: 10px;
  }

  .btn-box-tool {
    padding: 5px;
    font-size: 12px;
    background: transparent;
    color: #97a0b3;
  }

  .direct-chat-messages {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    padding: 15px 15px 0 15px;
  }

  .direct-chat-messages,
  .direct-chat-contacts {
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    -moz-transition: -moz-transform 0.5s ease-in-out;
    -o-transition: -o-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
  }

  .direct-chat-msg {
    margin-bottom: 10px;
  }

  .direct-chat-msg,
  .direct-chat-text {
    display: block;
  }

  .direct-chat-info {
    display: block;
    margin-bottom: 2px;
    font-size: 12px;
  }

  .direct-chat-timestamp {
    color: #0f5edd;
  }

  .direct-chat-img {
    border-radius: 50%;
    float: left;
    width: 35px;
    height: 35px;
    margin-top: 5px;
  }

  .direct-chat-text {
    border-radius: 5px;
    position: relative;
    padding: 8px 10px;
    border-radius: 8px;
    background: #f0f6ff;
    margin: 5px 0 0 50px;
    color: #0b1956;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  span.direct-chat-name,
  .direct-chat-timestamp {
    padding-bottom: 5px;
  }

  .direct-chat-msg,
  .direct-chat-text {
    display: block;
  }

  .direct-chat-text:before {
    border-width: 6px;
    margin-top: -6px;
  }

  .direct-chat-text:after,
  .direct-chat-text:before {
    position: absolute;
    right: 100%;
    top: 15px;
    border: solid transparent;
    border-right-color: #f0f6ff;
    content: " ";
    height: 0;
    width: 0;
    pointer-events: none;
  }

  .direct-chat-text:after {
    border-width: 5px;
    margin-top: -5px;
  }

  .direct-chat-text:after,
  .direct-chat-text:before {
    position: absolute;
    right: 100%;
    top: 15px;
    border: solid transparent;
    border-right-color: #d2d6de;
    content: " ";
    height: 0;
    width: 0;
    pointer-events: none;
  }

  :after,
  :before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .direct-chat-msg:after {
    clear: both;
  }

  .direct-chat-msg:after {
    content: " ";
    display: table;
  }

  .direct-chat-info {
    display: block;
    margin-bottom: 2px;
    color: #0f5edd;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 8px;
  }

  .right .direct-chat-img {
    float: right;
  }

  .direct-chat-warning .right>.direct-chat-text {
    border-radius: 8px;
    background: #deebff;
    color: #0b1956;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .right .direct-chat-text {
    margin-right: 50px;
    margin-left: 0;
  }

  .box-footer {
    padding: 10px 0 5px 0;
  }

  .box-footer input {
    border-radius: 8px !important;
    border: 1px solid rgba(15, 94, 221, 0.3);
    background: #fff;
    height: 48px;
    color: #545454;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .box-header:before,
  .box-body:before,
  .box-footer:before,
  .box-header:after,
  .box-body:after,
  .box-footer:after {
    content: " ";
    display: table;
  }

  .input-group-btn {
    position: relative;
    font-size: 0;
    white-space: nowrap;
  }

  .input-group-btn:last-child>.btn,
  .input-group-btn:last-child>.btn-group {
    z-index: 2;
    margin-left: -1px;
    padding-right: 0;
  }

  button.gift-subscription {
    border-radius: 50px;
    background: #0f5edd !important;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: $font-lighter;
    line-height: 24px;
    height: 50px;
    width: 100%;
  }

  .you-longer-access {
    border-radius: 8px;
    border: 1px solid rgba(233, 128, 0, 0.3);
    background: #fff7e3;
    padding: 10px 25px;
    margin-top: -50px;
    z-index: 9;
    position: relative;
  }
}

.form-select {
  border-radius: 8px;
  border: 1px solid rgba(15, 94, 221, 0.3);
  height: 36px;
  font-size: 14px;
  color: #0b1956;
}

.tags-inputs .rti--container {
  border: 0 !important;
  background: transparent;
  border-color: rgba(15, 94, 221, 0.2) !important;
  padding-left: 0;
  padding-right: 0;
  --rti-main: none !important;
}

.tags-inputs input.rti--input {
  background: transparent;
}

.tags-inputs input.rti--input:focus {
  border: 0;
  outline: none;
}

.tags-inputs .rti--tag {
  background: #0b1956;
  color: #fff;
  font-size: 12px;
  border-radius: 4px;
}

.tags-inputs .rti--tag button {
  color: #fff;
}

/********** Offcanvas **********/

.offcanvas {
  border-radius: 20px;
  background: #0f5edd;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.offcanvas-body {
  padding: 40px;
}

.right-panel-shape {
  height: calc(100vh - 0px);
  left: 0;
  right: 0;
  top: 0;
  z-index: 9;
  background: url("../../assets/images/left-panel-shape.png") center bottom no-repeat;
  background-size: contain;
}

.profile-list {
  position: absolute;
  z-index: 99;

  .profile-name {
    color: $color-secondary-white;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-top: 12px;
    padding-left: 15px;
    display: inline-block;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  ul li {
    list-style-type: none;
    padding-bottom: 20px;
  }

  ul li a {
    color: $color-secondary-white;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-left: 10px;
  }

  ul li a:hover {
    color: $color-secondary-white !important;
  }
}

button.border-btn,
button.fill-btn {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: $font-lighter;
  line-height: 24px;
  width: 150px;
  height: 50px;
  border-radius: 50px;
}

button.border-btn {
  color: $color-secondary;
  border: 1.5px solid #0f5edd;
  background: #fff;
}

button.fill-btn {
  color: $color-secondary-white;
  border: 1.5px solid #0f5edd;
  background: #0f5edd !important;
}

.modal-sm {
  // max-width: 400px !important;
  max-width: 600px !important;
}

.modal-content {
  border-radius: 16px;
}

.cursor {
  cursor: pointer;
}

/********** Table design here **********/
.table-data tr th {
  border: 0 !important;
  color: #0b1956;
  font-size: 12px;
  font-weight: $font-lighter;
  line-height: 16px;
}

.table-data tr,
.table-data tr td {
  border: 0 !important;
  padding: 10px;
}

.table-striped>tbody>tr:nth-of-type(2n + 1)>* {
  background: #f3f7fd !important;
  box-shadow: none;
}

.table-hover>tbody>tr:hover>* {
  background: #f3f7fd !important;
  box-shadow: none;
}

.table-data tr td {
  color: #545454;
  font-size: 12px;
  font-weight: $font-lighter;
  line-height: 16px;
}

.table-data tr td a {
  color: $color-secondary;
  text-decoration: underline;
}

.text-box {
  border-radius: 12px;
  border: 1px solid rgba(15, 94, 221, 0.2);
  height: 34px;
  width: 100%;
  padding: 10px;
}

/********** Media Query **********/

@media (max-width: 1024px) {
  .mobile-search {
    margin-left: 255px;
    position: relative;
  }

  .iPad-search {
    position: absolute;
    width: 390px;
    right: 20px;
    top: 20px;
    margin-right: 0;
  }

  .card-box .min-height .ratings svg {
    width: 13px;
  }

  .modal-90w {
    max-width: 90%;
  }

  .modal-65w {
    max-width: 65%;
  }

}

@media (max-width: 768px) {
  .mobile-search {
    margin-left: 240px;
    position: relative;
  }

  .iPad-search {
    position: absolute;
    width: 390px;
    right: 20px;
    top: 20px;
    margin-right: 0;
  }

  .modal-90w {
    max-width: 90%;
  }

  .modal-65w {
    max-width: 65%;
  }

  .custom-modal-box .modal-content {
    padding: 30px;
  }
}

@media (max-width: 767px) {
  .header-nav {
    border-radius: 0px 0px 24px 24px;
    background: #0f5edd !important;
    height: 90px !important;
  }

  .header-nav .navbar {
    padding: 0;
    position: fixed;
    width: 95%;
    top: 30px;
    right: 20px;
    left: 10px;
  }

  .iPad-search {
    position: relative;
    right: 0;
    left: 0;
    top: 0;
    width: auto;
  }

  .app-body {
    margin-bottom: 80px;
  }

  #main-wraper {
    background: #f3f7fd;
  }

  .modal-content {
    padding: 20px;
  }

  .custom-modals input {
    width: 60px;
    height: 60px;
  }

  .pb5 {
    padding-bottom: 10px;
  }

  .mobile-search {
    position: absolute;
    top: 90px;
    width: 100%;
    left: 0;
    right: 0;
    background: #f3f7fd;
    padding: 18px 0;
    margin-left: 0;

    .d-flex {
      display: inherit !important;
    }
  }

  .search-box {
    margin-top: 0 !important;
  }

  .top-empty {
    // padding-top: 90px !important;
    padding-top: 80px !important;
  }

  .pm10 {
    padding-bottom: 10px;
    display: none;
  }

  .mob-top {
    margin-top: 20px;
  }

  .notification-dd .dropdown-menu {
    min-width: 320px;
  }

  .notification-dd .dropdown-menu-end[data-bs-popper] {
    right: auto;
    left: auto;
  }

  .pm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .modal-90w {
    max-width: 100% !important;
  }

  .modal-65w {
    max-width: 100% !important;
  }

  .custom-modal-box .modal-content {
    padding: 20px;
  }

  .custom-modal-box .profile-bg-border {
    width: 80px;
    height: 80px;
  }

  /********** Mobile Offcanvas **********/

  .mobile-lg-offcanvas {
    width: 100% !important;
  }

  .offcanvas {
    border-radius: 0 !important;
  }

  .offcanvas .btn-close {
    background: url(../images//off-close.svg) right top no-repeat;
    width: 32px;
    height: 32px;
    margin-top: 9px;
  }

  .offcanvas-body {
    padding: 0 20px;
  }

  .mob-releative {
    position: relative !important;
  }

  .profile-mobile-flw .modal-dialog {
    margin: 0;
    padding: 0;
  }

  .profile-mobile-flw .modal-content {
    border-radius: 0;
  }

  .profile-mobile-flw .btn-close {
    display: none;
  }

  .btn-close {
    top: 25px;
    right: 25px;
  }

  .mobile-profile-modal {
    margin-top: 80px;
  }

  .mobile-profile-tabs {
    border-radius: 0px 0px 24px 24px;
    background: #0f5edd;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 90px;
  }

  .fs-12 {
    font-size: 10px !important;
  }
}

.button-loader {
  height: 15px;
  margin-right: 5px;
}

.selfHealth-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-data-available-box {
  .noDataMessage {
    width: 100%;
    /* height: 100%; */
    z-index: 10000000000000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  .bg {
    background: "#000000";
    opacity: 0.7;
    width: 100%;
    height: 100% !important;
    position: absolute;
    top: 0;
  }

  .center-data {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .center-chat-data {
    text-align: center;
    // margin-top: 95px;
  }
}

// customUplaodField
.file-upload {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 400px;
  /* Set a max-width if needed */
  background-color: #f3f7fd;
  border-radius: 4px;
  padding: 8px 12px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #1f2933;
  border-radius: 12px;
  border: 1px solid rgba(15, 94, 221, 0.2);
  // box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.file-upload-label {
  display: inline-block;
  width: calc(100% - 100px);
  /* Adjust width as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  vertical-align: middle;
}

.file-upload-input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 10;
}

// customUplaodField




@media (max-width: 767px) {
  .right-panel-shape {
    height: calc(70vh - 0px);
    background: url("../../assets/images/mobile-profile-shade.png") center bottom no-repeat;
    background-size: contain;
  }

  .plr {
    padding-left: 0;
    padding-right: 0;
  }

  .mobile-close .btn-close {
    top: 35px;
    right: 35px;
  }

  .notifi-in-mobile {
    position: absolute;
    right: 0;
    top: 0;
  }

  .profile-in-mobile {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.position-fixed {
  z-index: 9999;
}

.qualification-input .rti--input {
  width: 100% !important;
}

.qualification-input .rti--container {
  padding-left: 0;
  padding-right: 0;
}

.date-pick .react-datepicker-wrapper .react-datepicker__input-container input {
  color: #0B1956 !important;
  font-weight: 500 !important;
}

.input-space input {
  padding-left: 125px;
}

.input-space.plrspace input{
  padding-left: 65px !important
}

.input-space .arrow-pos {
  right: 13px !important;
  top: 13px !important;
}

label.input-group-text.browse-files {
  color: #0B1956;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-decoration: underline;
  cursor: pointer;
  background: transparent;
  border: 0;
  padding: 0;
}

.custom-file-upload {
  border-radius: 12px;
  border: 1px solid rgba(15, 94, 221, 0.2);
  background: #F3F7FD;
  height: 50px;
  color: #0B1956;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.custom-file-upload {
  .file-name {
    width: 80%;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    color: #545454;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }

  input[type=file] {
    margin: 0 !important;
    padding: 0 !important;
    height: 36px;
    position: absolute;
    opacity: 0;
    width: 90px;
    cursor: pointer;
  }

  .input-group-text {
    position: relative;
    font-size: 14px;
  }
}